import 'bootstrap';
import './app.scss';
import axios from 'axios';
$(() => {
    const $form = $('.contact-form');
    if (!$form.length) {
        return;
    }
    $form.on('submit', (event) => {
        $(".contact-submit").attr('disabled', '');
        event.preventDefault();
        axios.post('/api/signup', new FormData(event.currentTarget))
            .then(success)
            .catch(error);
    });
    function success() {
        $form.addClass('d-none');
        $('.contact-success').removeClass('d-none');
    }
    function error(error) {
        console.log(error);
        $('.contact-error').removeClass('d-none');
    }
});
